          
import React, { useEffect, useState } from 'react';
import {
  initMercadoPago,
  createCardToken,
  CardNumber,
  SecurityCode,
  ExpirationDate,
} from '@mercadopago/sdk-react';

initMercadoPago('APP_USR-1f69a0a9-f218-4517-9222-0d8d95a25de4');

const Mpago = () => {
  const[suscriptores, setSuscriptores] = useState({})
  const urlsuscriptores = process.env.REACT_APP_CONSULTA_SUSCRIPTORES

  const cardToken = async () => {
    const response = await createCardToken({
      cardholderName: 'Job Sael Torres Tejeda',
    })
    console.log('Card Token Response = ', response)
    console.log(response.id)
  }

  useEffect(()=>{
    fetch(urlsuscriptores,{
      method: 'GET',  
    })
      .then(res => res.json())
      .then(data => setSuscriptores(data))
      .catch(error => console.log('error', error));
  },[])

  console.log(suscriptores.results)

    return(
        <>
          <CardNumber placeholder='Número de tarjeta'/>
          <SecurityCode placeholder='Código de seguridad' />
          <ExpirationDate placeholder='Vencimiento' mode='short'/>
          <button onClick={() => cardToken()}>Pagar</button>
        </>
    )
}
export default Mpago;